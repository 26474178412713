@font-face
  font-family: "wask_new_bold_italic_webfont"
  src: url("../assets/wask_new_bold_italic-webfont.eot")
  src: url("../assets/wask_new_bold_italic-webfont.woff") format("woff")
  font-weight: normal
  font-style: normal

@font-face
  font-family: "NexaBold"
  src: url("../assets/NexaBold.ttf.eot")
  src: url("../assets/NexaBold.ttf.woff") format("woff")
  font-weight: normal
  font-style: normal

@font-face
  font-family: "NexaLight"
  src: url("../assets/NexaLight.ttf.eot")
  src: url("../assets/NexaLight.ttf.woff") format("woff")
  font-weight: normal
  font-style: normal
