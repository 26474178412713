@use "vars" as *
  
.proyects
  width: 100%
  min-height: 100vh
  background: $black
  padding: 128px 0
  display: flex 
  align-items: center
  justify-content: center

  &__content
    display: grid
    grid-template-columns: repeat(2, minmax(0, 1fr))
    width: 100%
    height: 100%
    gap: 48px

    &__item
      width: 100%
      cursor: pointer

      img
        width: 100%
