@use "vars" as *

.values
  width: 100%
  min-height: 100vh
  background: $yellow
  display: grid
  grid-template-columns: repeat(3, minmax(0, 1fr))
  grid-template-rows: repeat(7, minmax(0, 1fr))
  column-gap: 64px
  row-gap: 16px

  [class*="item"]
    display: flex
    // background: $gray-100

  &__item
    
    &__title
      cursor: pointer

    &__conection
      grid-column: 1 / 2
      grid-row: 1 / 3

    &__hands
      grid-column: 1 / 2
      grid-row: 3 / 6

    &__comunity
      grid-column: 1 / 2
      grid-row: 6 / 8

    &__noise
      grid-column: 2 / 3
      grid-row: 1 / 3

    &__storytellers
      grid-column: 2 / 3
      grid-row: 3 / 5

    &__adaptable
      grid-column: 2 / 3
      grid-row: 5 / 6

    &__feets
      grid-column: 2 / 3
      grid-row: 6 / 8

    &__waves
      grid-column: 3 / 4
      grid-row: 3 / 5

    &__freedom
      grid-column: 3 / 4
      grid-row: 6 / 8

  @media (max-width: $lg)
    column-gap: 48px

  @media (max-width: $md)
    grid-template-columns: 1fr 48px 1fr
    grid-template-rows: repeat(11, minmax(0, 1fr))
    column-gap: 32px
    row-gap: 16px

    &__item

      &__noise
        grid-column: 1 / 2
        grid-row: 1 / 4

      &__storytellers
        grid-column: 1 / 3
        grid-row: 4 / 6

      &__adaptable
        grid-column: 1 / 3
        grid-row: 6 / 8

      &__waves
        grid-column: 1 / 2
        grid-row: 8 / 10

      &__freedom
        grid-column: 1 / 2
        grid-row: 10 / 12

      // right

      &__conection
        grid-column: 2 / 4
        grid-row: 1 / 4

      &__hands
        grid-column: 3 / 4
        grid-row: 4 / 8

      &__comunity
        grid-column: 2 / 4
        grid-row: 8 / 10

      &__feets
        grid-column: 3 / 4
        grid-row: 10 / 12

  // Content

  &__item

    &__image
      width: 100%
      height: 100%
      object-fit: cover
      display: none !important

    &__conection
      align-items: center
      justify-content: center

    &__hands

      > div
        width: 100%
        height: 100%
        overflow: hidden
        position: relative

        img
          width: 100%
          height: 100%
          object-fit: cover
          position: absolute

    &__comunity
      align-items: flex-start
      justify-content: center

    &__noise

      > div
        width: 100%
        height: 100%
        overflow: hidden
        position: relative

        img
          width: 100%
          height: 100%
          object-fit: cover
          position: absolute


    &__storytellers
      align-items: flex-start
      justify-content: flex-start

    &__adaptable
      align-items: flex-start
      justify-content: flex-end

    &__feets
      
      > div
        width: 100%
        height: 100%
        overflow: hidden
        position: relative

        img
          width: 100%
          height: 100%
          object-fit: cover
          position: absolute

    &__waves

      > div
        width: 100%
        height: 100%
        overflow: hidden
        position: relative

        img
          width: 100%
          height: 100%
          object-fit: cover
          position: absolute

    &__freedom
      align-items: center
      justify-content: flex-start

    @media (max-width: $md)

      &__conection
        align-items: center
        justify-content: center

      &__hands
        align-items: center
        justify-content: center

      &__comunity
        align-items: center
        justify-content: flex-start

      &__noise
        align-items: center
        justify-content: center

      &__storytellers
        align-items: flex-start
        justify-content: center

      &__adaptable
        align-items: flex-start
        justify-content: center

      &__feets
        align-items: center
        justify-content: center

      &__waves
        align-items: center
        justify-content: center

      &__freedom
        align-items: center
        justify-content: center
