.navbar
  position: fixed
  top: 0
  left: 0
  z-index: 999
  background-color: rgba(0, 0, 0, 0.4)
  height: 60px
  width: 100%

  &__content
    height: 60px
    display: flex
    align-items: center
    justify-content: space-between

  &__menu
    width: 32px
    height: 32px
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
