@use "vars" as *
@use "mixins" as *

.footer
  background: $black
  width: 100%
  padding: 64px 0

  &__content
    display: grid
    grid-template-columns: repeat(4, minmax(0, 1fr))
    width: 100%
    justify-content: center
    gap: 48px

    &__item
      width: 100%
      color: $white

      &__logo
        width: 100%
        max-width: 130px

        + span
          margin-top: 14px

      span
        font-family: "NexaLight", sans-serif
        text-transform: uppercase
        font-size: 1rem
        display: block
        margin-top: 8px

        + span
          margin-top: 14px

      &__social
        display: flex
        gap: 16px

        &__icon
          display: flex
          align-items: center
          margin-top: 12px
          font-size: 20px

  @media (max-width: $md)
    
      &__content
        gap: 16px
        grid-template-columns: repeat(2, minmax(0, 1fr))

        &__item

          &__logo
            max-width: 110px

        span
          font-size: .9rem

          + span
            margin-top: 8px