@use "vars" as *

.brands
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  padding: 128px 0
  background: white

  &__container
    text-align: center
    display: flex
    flex-direction: column
    gap: 48px

  &__list
    display: grid
    grid-template-columns: repeat(5, 1fr)
    gap: 1px
    justify-content: center

    &__item
      position: relative
      padding-bottom: 75%
      // background: $gray-200
      display: flex
      align-items: center
      justify-content: center

      img
        position: absolute
        width: 60%
        top: 50%
        left: 50%
        transform: translate(-50%, -50%)


  @media (max-width: $md)

    &__container
      gap: 64px

    &__list
      grid-template-columns: repeat(3, 1fr)

  @media (max-width: $sm)

    &__list
      grid-template-columns: repeat(2, 1fr)

    &__container
      gap: 48px



  