@use "vars" as *
@use "mixins" as *
  
.about
  width: 100%
  min-height: 100vh
  display: flex
  align-items: center
  justify-content: center
  padding: 64px 0
  @include backgroundImage("../assets/about.jpg")

  &__container
    width: 100%
    display: grid
    align-items: center
    grid-template-columns: repeat(2, minmax(0, 1fr))
    grid-gap: 64px

    @media (max-width: $md)
      grid-gap: 32px
      grid-template-columns: minmax(0, 1fr)

  &__catchword
    margin-top: 64px
    display: flex
    align-items: center
    font-family: "NexaLight", sans-serif
    font-size: 1.4rem

    &__icon
      width: 64px
      height: 64px
      background: $black
      border-radius: 50% 
      margin-left: 48px
      display: flex
      align-items: center
      justify-content: center
      cursor: pointer

      img
        width: 50%
        height: auto
        object-fit: contain

    @media (max-width: $md)
      margin-top: 32px

      &__icon
        width: 48px
        height: 48px
        margin-left: 32px

