$yellow: #dbf02b
$green: #96f54f
$blue: #0021ff
$purple: #8a4fff
$cyan: #c2f0de
$black: #000000
$white: #ffffff

$gray-50: #FAFAFA
$gray-100: #F5F5F5
$gray-200: #EEEEEE
$gray-300: #E0E0E0
$gray-400: #BDBDBD
$gray-500: #9E9E9E
$gray-600: #757575
$gray-700: #616161
$gray-800: #424242
$gray-900: #212121

$xs: 379.98px
$sm: 575.98px
$md: 767.98px
$lg: 991.98px
$xl: 1199.98px
