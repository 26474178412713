@use "vars" as *

.accordion
  text-align: center
  display: flex
  flex-direction: column

  &__panel
    display: none
    padding: 16px 0 32px

    &.active
      display: block

  button
    height: auto
    background: none
    border: none
    cursor: pointer
    font-size: 4.5rem
    transition: color 0.4s linear
    font-family: "NexaBold", sans-serif
    color: $gray-900

    @media (max-width: $lg)
      font-size: 3rem

    @media (max-width: $md)
      font-size: 2rem

    @media (max-width: $sm)
      font-size: 1.5rem

    &:hover
      color: $white

    &.active 
      color: $white
      
  p
    font-size: 1.5rem
    @media (max-width: $lg)
      font-size: 1.5rem

    @media (max-width: $md)
      font-size: 1rem

    @media (max-width: $sm)
      font-size: 1rem

  ul li
    font-size: 1.5rem
    @media (max-width: $lg)
      font-size: 1.5rem

    @media (max-width: $md)
      font-size: 1rem

    @media (max-width: $sm)
      font-size: 1rem

  


