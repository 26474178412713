@use "vars" as *

.menu
  width: 100%
  height: 100%
  min-height: 100vh
  position: fixed
  background-color: rgba(0,0,0,.8)
  z-index: 9999
  top: 0
  left: 0
  align-items: center
  justify-content: center
  flex-direction: column
  text-align: center
  overflow: hidden
  display: none
  opacity: 0
  transition: opacity .3s ease-in-out

  &.active
    display: flex

  &.visible
    opacity: 1

  &__close
    position: fixed
    top: 20px
    right: 20px
    width: 48px
    height: 48px
    border-radius: 50%
    cursor: pointer
    z-index: 9999

  nav
    a
      color: white
      text-decoration: none
      font-size: 2rem
      line-height: 3rem
      transition: all .3s ease-in-out

      @media (max-width: $md)
        font-size: 1.6rem
        line-height: 2.4rem

      &:hover
        color: $yellow
