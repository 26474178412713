@use "vars" as *

.hero
  width: 100%
  min-height: 100vh
  position: relative
  background-color: $gray-800

  &__left, &__right
    top: 0
    width: 50%
    height: 100%
    position: absolute

    img
      width: 100%
      height: 100%
      object-fit: cover

  &__left
    left: 0

  &__right
    left: 50%

  &__content
    position: relative
    width: 100%
    height: 100%
    min-height: 100vh
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    text-align: center
    z-index: 2

    &__logo
      width: 100%
      max-width: 320px
      margin-top: 128px
      margin-bottom: 64px
      position: relative

      &__animated
        position: absolute
        top: 0
        width: 29%
        height: 100%

        img
          width: 100%
          object-fit: cover
          animation-name: bounce-1
          animation-timing-function: ease
          animation-duration: 5s
          animation-iteration-count: infinite

      img
        width: 100%
        height: auto
        object-fit: contain

    &__desktop
      display: block

    &__mobile
      display: none

  @media (max-width: $lg)

    &__content

      &__logo
        max-width: 280px
        margin-bottom: 48px

  @media (max-width: $md)

    &__left
      width: 100%

    &__right
      display: none

    &__content

      &__desktop
        display: none

      &__mobile
        display: block

  @media (max-width: $sm)

    &__content

      &__logo
        max-width: 150px
        margin-bottom: 32px

@keyframes bounce-1
  0%
    transform: translateX(0)
  20%
    transform: translateX(250%)

  40%, 100%
    transform: translateX(0)
