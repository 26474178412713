@use "vars" as *

$gradien-yellow: linear-gradient(-45deg, rgba($yellow,0) 60%, $yellow)
$gradien-blue: linear-gradient(45deg, rgba($blue,0) 10%, $blue )
$gradien-green: linear-gradient(45deg, $green, rgba($green,0) 100%)
$gradien-cyan: linear-gradient(-45deg, $cyan, rgba($cyan,0) 100%)

$gradient: $gradien-yellow, $gradien-blue, $gradien-green, $gradien-cyan

.services
  width: 100%
  min-height: 100vh
  background: $gradient
  display: flex
  align-items: center
  justify-content: center

  

