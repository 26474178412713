@use "vars" as *
  
[class*="wrapper"]
  width: 100%
  padding: 0 16px
  display: grid
  justify-content: center

.wrapper

  &--xl
    grid-template-columns: minmax(0, $xl)

  &--lg
    grid-template-columns: minmax(0, $lg)

  &--md
    grid-template-columns: minmax(0, $md)