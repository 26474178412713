@use "vars" as *
  
.container
  width: 100%
  display: grid
  align-items: center
  justify-content: center
  padding: 0 16px

  &.lg
    grid-template-columns: minmax(0, $lg)

