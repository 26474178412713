@forward "reset"
@forward "fonts"

@use "vars" as *

@import url("https://cdn.lineicons.com/3.0/lineicons.css")

html, body
  height: 100%
  
body
  color: $black
  font-family: "NexaBold", sans-serif


h1
  font-family: "wask_new_bold_italic_webfont", sans-serif
  font-weight: bold
  font-style: italic
  color: $yellow
  font-size: 5rem

  @media (max-width: $sm)
    font-size: 4rem

h2
  font-family: "NexaBold", sans-serif
  font-weight: 300
  font-size: 4.5rem

  @media (max-width: $lg)
    font-size: 4rem

  @media (max-width: $md)
    font-size: 3.5rem

  @media (max-width: $sm)
    font-size: 2.5rem

h4
  font-family: "NexaBold", sans-serif
  font-weight: 300
  font-size: 1.6rem
  border-bottom: solid 1px $black
  padding-bottom: 5px

  @media (max-width: $lg)
    font-size: 1.5rem

  @media (max-width: $md)
    font-size: 1.2rem

  @media (max-width: $sm)
    font-size: 1rem

p
  font-size: 1.25rem
  font-weight: normal
  line-height: 1.25

  @media (max-width: $sm)
    font-size: 1.15rem

  span
    background: $yellow

  + p
    margin-top: 24px

    @media (max-width: $sm)
      margin-top: 16px

  + ul 
    margin-top: 24px


blockquote
  font-family: "wask_new_bold_italic_webfont", sans-serif
  font-weight: bold
  font-style: italic
  color: $yellow
  font-size: 7rem
  line-height: 100%

  @media (max-width: $lg)
    font-size: 5rem

  @media (max-width: $sm)
    font-size: 4rem

