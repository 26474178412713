@use "vars" as *
@use "mixins" as *

$gradien-yellow-right: linear-gradient(-45deg, $yellow, rgba($yellow,0) 25%)
$gradien-yellow-left: linear-gradient(45deg, $yellow, rgba($yellow,0) 25%)
$gradien-purple-right: linear-gradient(45deg, rgba($purple,0) 45%, $purple)
$gradien-purple-left: linear-gradient(-45deg, rgba($purple,0) 45%, $purple)
$gradient: $gradien-yellow-right, $gradien-yellow-left, $gradien-purple-left, $gradien-purple-right

.contact
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  padding: 128px 0
  background: $gradient

  &__container
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    text-align: center
    gap: 96px

  &__info
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 16px

    &__item
      font-family: "NexaLight", sans-serif
      font-size: 2.8rem

      i
        font-size: 2.5rem
        margin-right: 12px
        padding-bottom: 8px

  &__social
    display: flex
    align-items: center
    justify-content: center
    gap: 32px
    font-size: 1.5rem
    cursor: pointer

  @media (max-width: $md)

    &__container
      gap: 64px

    &__info

      &__item
        font-size: 2.5rem

        i
          font-size: 2.25rem
          margin-right: 10px

  @media (max-width: $sm)

    &__container
      gap: 48px

    &__info

      &__item
        font-size: 2rem

        i
          font-size: 1.8rem
          margin-right: 8px
